@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: hsl(195, 22%, 4%);
}


@layer components{
    .navBar1 {
      @apply w-full top-0 absolute items-center justify-between h-[90px]
      flex left-0 right-0 z-10 px-10 md:top-0 ;
    }
  
    .buttonG {
      @apply bg-medium-turquoise font-bold text-dark-charcoal hover:bg-[#4dd49c] 
             rounded-full text-sm px-6 py-3 text-center w-1/3 transition-all duration-300
             shadow-md hover:shadow-lg transform hover:scale-105;
    }
    
    .buttonB {
      @apply bg-blue-primary font-bold text-vary-light-gray hover:bg-[#1e3a8a] 
             rounded-full text-sm px-6 py-3 text-center w-1/3 transition-all duration-300
             shadow-md hover:shadow-lg transform hover:scale-105;
             
    }
    
    .buttonNavBar {
      @apply bg-medium-turquoise font-bold text-dark-charcoal hover:bg-[#4dd49c] 
             rounded-full text-sm px-6 py-3 text-center w-1/3 transition-all duration-300
             shadow-md hover:shadow-lg transform hover:scale-105;
    }
    
    
}

