.product-details-container {
  padding: 20px;
  margin: 60px;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.filter-options {
  display: flex;
  color: white;
}

.filter-item {
  margin-right: 20px;
}

.filter-item label {
  font-weight: bold;
  margin-right: 5px;
}

.filter-item select, .filter-item input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter-item input {
  width: 200px;
}

.filter-item.right {
  margin-left: auto;
}

.cart-info {
  display: flex;
  align-items: center;
}

.cart-icon {
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.cart-count {
  margin-left: 5px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 14px;
}

.total-price {
  margin-left: 20px;
  font-weight: bold;
  color: white;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.product-card {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: calc(50% - 20px); /* Ajusta el ancho para dos columnas */
  display: flex;
  align-items: flex-start; /* Alinea el contenido desde el inicio */
  position: relative;
  overflow: hidden; /* Asegura que nada salga del borde de la tarjeta */
}

.product-info {
  flex: 1; /* Ocupa el espacio disponible */
}

.product-card img {
  max-width: 150px; /* Ajusta el tamaño máximo de la imagen */
  height: auto;
  position: absolute;
  right: 10px;
  top: 10px;
  object-fit: cover; /* Ajusta la imagen para cubrir el área sin distorsión */
  border-radius: 5px; /* Asegura que los bordes de la imagen coincidan con los de la tarjeta */
}

.product-card h3 {
  margin-top: 0;
}

.product-card p {
  margin: 5px 0;
}

.product-card .price {
  font-weight: bold;
}

.product-card button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.product-card button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.pagination button.active {
  background-color: #0056b3;
}

.pagination button:hover {
  background-color: #0056b3;
}

.cart-dropdown {
  position: absolute;
  right: 20px;
  top: 60px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.close-cart {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin: 0;
}

.cart-dropdown h3 {
  margin-top: 0;
}

.cart-dropdown table {
  width: 100%;
  border-collapse: collapse;
}

.cart-dropdown th, .cart-dropdown td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.cart-dropdown th {
  background-color: #f2f2f2;
}

.cart-dropdown button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.cart-dropdown button:hover {
  background-color: #0056b3;
}

/* ProductDetails.css */

/* ProductDetails.css */

/* Estilos para el formulario de inicio de sesión */
.login-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fondo difuminado */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que esté por encima del resto del contenido */
}

.login-form form {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.login-form label {
  display: block;
  margin-bottom: 8px;
}

.login-form input {
  display: block;
  margin-bottom: 12px;
  width: 100%;
  padding: 8px;
}

.login-form button {
  padding: 10px 15px;
}
