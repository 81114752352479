.checkout-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 60px;
    background-color: #1a1a1a; /* Fondo oscuro */
    color: #f8f8f8; /* Texto claro */
    border-radius: 10px;
  }
  
  .checkout-left, .checkout-right {
    width: 48%; /* Ajuste de ancho para dos columnas */
  }
  
  .checkout-left {
    padding-right: 10px;
  }
  
  .checkout-right {
    padding-left: 10px;
  }
  
  .checkout-container h2 {
    margin-bottom: 20px;
    color: #f8f8f8; /* Texto claro */
  }
  
  .checkout-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .checkout-container th, .checkout-container td {
    border: 1px solid #444; /* Borde más oscuro */
    padding: 10px;
    text-align: left;
    color: #f8f8f8; /* Texto claro */
  }
  
  .checkout-container th {
    background-color: #333; /* Fondo de encabezado oscuro */
  }
  
  .payment-methods h3 {
    margin-bottom: 20px;
    color: #f8f8f8; /* Texto claro */
  }
  
  .payment-options {
    display: flex;
    flex-direction: column;
  }
  
  .payment-option {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #444;
    border-radius: 5px;
    cursor: pointer;
    background-color: #333; /* Fondo de opción de pago */
    color: #f8f8f8; /* Texto claro */
  }
  
  .payment-option span {
    margin-left: 10px;
  }
  
  .payment-option.selected {
    border-color: #007bff; /* Borde resaltado para la opción seleccionada */
    background-color: #555; /* Fondo de opción seleccionada */
  }
  
  .payment-option svg {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .payment-methods button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .payment-methods button:disabled {
    background-color: #555; /* Fondo deshabilitado */
    cursor: not-allowed;
  }
  .checkout-left button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .checkout-left button:hover {
    background-color: #0056b3;
  }
  /* Añade aquí tus estilos CSS para los formularios de pago */
.error-message {
  color: red;
  margin-top: 10px;
}

/* button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
} */

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.payment-option.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.payment-options {
  display: flex;
  flex-direction: column;
}


/* Estilos generales para el contenedor del formulario */
.openpay-form-container {
  background-color: #2c2c2c; /* Fondo oscuro para el formulario */
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.openpay-form-container h3 {
  color: #f8f8f8;
  margin-bottom: 15px;
}

.openpay-form-container form {
  display: flex;
  flex-direction: column;
}

.openpay-form-container input {
  background-color: #1a1a1a;
  border: 1px solid #444;
  border-radius: 5px;
  color: #f8f8f8;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.openpay-form-container input::placeholder {
  color: #888;
}

.openpay-form-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.openpay-form-container button:hover {
  background-color: #0056b3;
}

.openpay-form-container .error-message {
  color: #fa755a;
  margin-top: 10px;
}
