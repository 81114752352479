.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  font-family: var(--poppins-font);
  width: 100%;
}
.about h2 {
  font-size: 3rem;
}
.about .sub-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: var(--pizza-red);
  font-family: var(--nothing-font);
  letter-spacing: 1px;
}

.about p {
  color: var(--light-gray);
  font-size: 1.2rem;
  line-height: 2rem;
}
.about-us .content h3,
.about-dedication .content h3,
.about-collaboration h3,
.about-customers h3,
.about-careers h3 {
  font-size: 2rem;
}

.about-us,
.about-dedication,
.about-customers {
  display: flex;
  flex-direction: column;
}
.about-careers,
.about-collaboration {
  display: grid;
  grid-template-columns: 1fr;
}

.about-careers img,
.about-collaboration img {
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  opacity: 0.8;
  object-fit: cover;
}
.about-careers .content,
.about-collaboration .content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.about-dedication .content,
.about-customers .content,
.about-careers .content,
.about-collaboration .content {
  padding: 1rem;
}

/**** About us ***/
.about-us .images {
  display: grid;
  grid-template-columns: 1fr;
}
.about-us .images img {
  width: 100%;
  min-height: 100%;
  height: 16rem;
  max-height: 100%;
  object-fit: cover;
}

.about-us,
.about-dedication {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us .content {
  position: relative;
  padding: 4rem;
  z-index: 98;
}
.about-us .content h2,
.about-us .content h3,
.about-us .content p {
  position: relative;
  z-index: 999;
}

.section-one-glass {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}
.about-us::after,
.about-dedication::after {
  content: "";
  background-color: var(--pizza-red);
  opacity: 0.3;
  width: 100%;
  height: 1px;
  bottom: 0;
  z-index: 2;
}

/**** About dedication ***/


.about-dedication .content {
  position: relative;
  z-index: 1;
  width: 90%;
  text-align: left;
  margin: 0 auto;
}
.dark-glass {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.411);
  backdrop-filter: blur(1px);
}
.black-glass {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.829);
  backdrop-filter: blur(3px);
}

/**** About customers ***/
.about-customers .content {
  margin: 0 auto;
}

.about-customers .images {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
}
.about-customers .images img {
  min-width: 10rem;
  width: 100%;
  min-height: 100%;
  height: 20rem;
  max-height: 100%;
  border-radius: 15px;
}

.img-glass {
  width: 100%;
  height: 20rem;
  bottom: 0;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.7;
}

/**** Responsive ***/

@media screen and (min-width: 700px) {
  .about-dedication .content,
  .about-customers .content,
  .about-careers .content,
  .about-collaboration .content {
    padding: 2rem;
  }
  .about-us .images {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1025px) {
  .about-dedication .content,
  .about-customers .content {
    width: 70%;
  }
  .about-customers .images {
    flex-direction: row;
  }
  .about-careers,
  .about-collaboration {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-us .images {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (min-width: 1110px) {
  .about-dedication .content,
  .about-customers .content {
    width: 50%;
  }
}
